@import 'variables.scss';

.cautionDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-panel {
    background-color: $white;
    max-width: 480px;
    width: 100%;
    border-radius: 16px;
    margin: 20px;
    padding: 28px 26px 20px;
    &-title {
      font-size: 3.2rem;
      text-align: center;
      svg {
        color: $global;
      }
      .error {
        color: $label-red;
      }
    }
    &-content {
      text-align: center;
      white-space: pre-wrap;
      font-size: 1.2rem;
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
      padding: 16px 0 18px;
    }
    &-actions__confirm {
      display: flex;
      justify-content: center;
      gap: 16px;
      padding: 18px 0;
    }
    &-actions__error {
      display: flex;
      justify-content: center;
      padding: 18px 0;
    }
  }
}
