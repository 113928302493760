@import 'variables.scss';

.wrapper {
  width: 100%;

  .item {
    width: 100%;
    padding: 4px 23px 4px 14px;
    border-radius: 3px;
    &.white {
      background-color: $white;
      border: 1px solid $border;
    }
    &.input {
      background-color: $input-bg;
      border: 1px solid $border;
    }
    &.ghost {
      background-color: transparent;
      border: none;
    }
    &.error {
      border: 1px solid $label_red;
    }
  }
  & .error-message {
    color: $label_red;
    font-size: 12px;
    word-break: break-all;
    white-space: break-spaces;
  }
}
